import React, { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import InputArea from './InputArea';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import PromptModal from './PromptModal';
import PromptIcon from './PromptIcon';
import { Prompt } from '../types';

interface ChatMessage {
  role: string;
  content: string;
}

interface Image {
  svg: string;
  png: string;
}

interface ChatWindowProps {
  chatHistory: ChatMessage[];
  setChatHistory: React.Dispatch<React.SetStateAction<ChatMessage[]>>;
  images: Image[];
  addImages: (newImages: Image[]) => void;
  clearImages: () => void;
  apiKey: string;
  setApiKey: (apiKey: string) => void;
  prompts: Prompt[];
  addPrompt: (newPrompt: Prompt) => void;
  updatePrompt: (updatedPrompt: Prompt) => void;
  selectedPrompt: Prompt | null;
  selectPrompt: (prompt: Prompt | null) => void;
  deletePrompt: (promptId: string) => void;
  clearChat: () => void;
  showTooltip: (message: string, position: { x: number; y: number }) => void;
}

function ChatWindow({
  chatHistory,
  setChatHistory,
  images,
  addImages,
  clearImages,
  apiKey,
  setApiKey,
  prompts,
  addPrompt,
  updatePrompt,
  selectedPrompt,
  selectPrompt,
  deletePrompt,
  clearChat,
  showTooltip
}: ChatWindowProps) {
  const [isPromptModalOpen, setIsPromptModalOpen] = useState(false);
  const [editingPrompt, setEditingPrompt] = useState<Prompt | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentStreamedMessage, setCurrentStreamedMessage] = useState('');
  const chatEndRef = useRef<HTMLDivElement>(null);

  const handleAddPrompt = () => {
    setEditingPrompt(null);
    setIsPromptModalOpen(true);
  };

  const handleEditPrompt = (prompt: Prompt) => {
    setEditingPrompt(prompt);
    setIsPromptModalOpen(true);
  };

  const handleSavePrompt = (prompt: Prompt) => {
    if (editingPrompt) {
      updatePrompt(prompt);
    } else {
      addPrompt(prompt);
    }
    setIsPromptModalOpen(false);
  };

  const handleDeletePrompt = (promptId: string) => {
    deletePrompt(promptId);
    setIsPromptModalOpen(false);
  };

  const sendMessage = async (message: string) => {
    if (!apiKey) {
      alert('请先在设置中设置 API Key');
      return;
    }

    setIsLoading(true);
    let fullMessage = message;
    if (selectedPrompt) {
      fullMessage = `${selectedPrompt.content}\n\n${message}`;
    }
    // 只显示用户输入的消息，不包含提示词
    const userMessage: ChatMessage = { role: 'user', content: message };
    setChatHistory(prev => [...prev, userMessage]);
    setCurrentStreamedMessage('');

    try {
      const response = await fetch('https://zmgpt.cc/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model: 'claude-3-5-sonnet-20240620',
          messages: [...chatHistory, { role: 'user', content: fullMessage }],
          stream: true,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body?.getReader();
      const decoder = new TextDecoder();

      if (!reader) {
        throw new Error('Response body is null');
      }

      let accumulatedMessage = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value);
        const lines = chunk.split('\n');
        for (const line of lines) {
          if (line.startsWith('data: ')) {
            try {
              const jsonData = JSON.parse(line.slice(5));
              if (jsonData.choices && jsonData.choices[0].delta && jsonData.choices[0].delta.content) {
                accumulatedMessage += jsonData.choices[0].delta.content;
                setCurrentStreamedMessage(accumulatedMessage);
              }
            } catch (error) {
              console.error('Error parsing JSON:', error);
            }
          }
        }
      }

      const aiMessage: ChatMessage = { role: 'assistant', content: accumulatedMessage };
      setChatHistory(prev => [...prev, aiMessage]);

      // 解析 AI 回复中的 SVG 内容
      const svgRegex = /<svg[\s\S]*?<\/svg>/gi;
      const svgMatches = accumulatedMessage.match(svgRegex);

      if (svgMatches) {
        const newImages = svgMatches.map((svg: string) => ({ svg, png: '' }));
        addImages(newImages);
      }
    } catch (error) {
      console.error('Error sending message:', error);
      alert(`发送消息时出错: ${error instanceof Error ? error.message : '未知错误'}\n请检查您的 API Key 和网络连接`);
    } finally {
      setIsLoading(false);
      setCurrentStreamedMessage('');
    }
  };

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatHistory, currentStreamedMessage]);

  return (
    <div className="w-1/2 flex flex-col h-full border-r">
      <div className="flex-1 overflow-y-auto p-4">
        {chatHistory.map((message, index) => (
          <div key={index} className="mb-4 text-left">
            <div className={`inline-block p-2 rounded-lg ${message.role === 'user' ? 'bg-blue-100' : 'bg-gray-100'}`}>
              <ReactMarkdown
                children={message.content}
                components={{
                  code({node, inline, className, children, ...props}: any) {
                    const match = /language-(\w+)/.exec(className || '')
                    return !inline && match ? (
                      <SyntaxHighlighter
                        children={String(children).replace(/\n$/, '')}
                        style={atomDark as any}
                        language={match[1]}
                        PreTag="div"
                        {...props}
                        className="text-sm overflow-x-auto"
                      />
                    ) : (
                      <code className={className} {...props}>
                        {children}
                      </code>
                    )
                  }
                }}
                className="text-sm break-words overflow-wrap-anywhere"
              />
            </div>
          </div>
        ))}
        {isLoading && (
          <div className="mb-4 text-left">
            <div className="inline-block p-2 rounded-lg bg-gray-100">
              AI 思考中...
            </div>
          </div>
        )}
        {currentStreamedMessage && (
          <div className="mb-4 text-left">
            <div className="inline-block p-2 rounded-lg bg-gray-100">
              <ReactMarkdown
                children={currentStreamedMessage}
                components={{
                  code({node, inline, className, children, ...props}: any) {
                    const match = /language-(\w+)/.exec(className || '')
                    return !inline && match ? (
                      <SyntaxHighlighter
                        children={String(children).replace(/\n$/, '')}
                        style={atomDark as any}
                        language={match[1]}
                        PreTag="div"
                        {...props}
                        className="text-sm overflow-x-auto"
                      />
                    ) : (
                      <code className={className} {...props}>
                        {children}
                      </code>
                    )
                  }
                }}
                className="text-sm break-words overflow-wrap-anywhere"
              />
            </div>
          </div>
        )}
        <div ref={chatEndRef} />
      </div>
      <div className="p-4 border-t">
        <div className="flex justify-between mb-4">
          <div className="flex space-x-2 overflow-x-auto">
            {prompts.map(prompt => (
              <PromptIcon
                key={prompt.id}
                prompt={prompt}
                onEdit={handleEditPrompt}
                isSelected={selectedPrompt?.id === prompt.id}
                onSelect={() => selectPrompt(prompt)}
                showTooltip={showTooltip}
              />
            ))}
          </div>
          <button onClick={handleAddPrompt} className="p-2 bg-green-500 text-white rounded-full hover:bg-green-600 transition-colors flex-shrink-0">
            <PlusIcon className="h-5 w-5" />
          </button>
        </div>
        <InputArea onSendMessage={sendMessage} selectedPrompt={selectedPrompt} />
      </div>
      <PromptModal
        isOpen={isPromptModalOpen}
        onClose={() => setIsPromptModalOpen(false)}
        onSave={handleSavePrompt}
        onDelete={handleDeletePrompt}
        prompt={editingPrompt}
      />
    </div>
  );
}

export default ChatWindow;