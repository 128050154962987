import React, { useState, useEffect } from 'react';

interface SettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
  setApiKey: (apiKey: string) => void;
}

function SettingsModal({ isOpen, onClose, setApiKey }: SettingsModalProps) {
  const [apiKeyInput, setApiKeyInput] = useState('');

  useEffect(() => {
    const storedApiKey = localStorage.getItem('apiKey') || '';
    setApiKeyInput(storedApiKey);
  }, [isOpen]);

  const handleSave = () => {
    setApiKey(apiKeyInput);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <h3 className="text-lg font-bold mb-4">设置</h3>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">API Key</label>
          <input
            type="password"
            value={apiKeyInput}
            onChange={(e) => setApiKeyInput(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            placeholder="输入您的 API Key"
          />
        </div>
        <div className="flex justify-end">
          <button onClick={onClose} className="mr-2 px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400">取消</button>
          <button onClick={handleSave} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">保存</button>
        </div>
      </div>
    </div>
  );
}

export default SettingsModal;