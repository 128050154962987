import React from 'react';
import ReactDOM from 'react-dom';

interface TooltipPortalProps {
  message: string;
  position: { x: number; y: number };
}

const TooltipPortal: React.FC<TooltipPortalProps> = ({ message, position }) => {
  return ReactDOM.createPortal(
    <div
      style={{
        position: 'fixed',
        left: position.x,
        top: position.y,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        color: 'white',
        padding: '6px 10px',
        borderRadius: '6px',
        fontSize: '12px',
        fontWeight: '500',
        letterSpacing: '0.2px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        zIndex: 1000,
        maxWidth: '200px',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        transition: 'opacity 0.2s ease-in-out',
      }}
    >
      {message}
    </div>,
    document.body
  );
};

export default TooltipPortal;