import React, { useState, useEffect } from 'react';
import ChatWindow from './components/ChatWindow';
import ImageGallery from './components/ImageGallery';
import SettingsModal from './components/SettingsModal';
import PromptModal from './components/PromptModal'; // 添加这行
import { systemPrompts } from './config/systemPrompts';
import { Prompt } from './types';
import { CogIcon, TrashIcon, ShoppingCartIcon } from '@heroicons/react/24/outline';
import TooltipPortal from './components/TooltipPortal';

interface ChatMessage {
  role: string;
  content: string;
}

interface Image {
  svg: string;
  png: string;
}

// 移除本地的 Prompt 接口定义

function App() {
  const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);
  const [images, setImages] = useState<Image[]>([]);
  const [apiKey, setApiKey] = useState<string>('');

  const [prompts, setPrompts] = useState<Prompt[]>([]);
  const [selectedPrompt, setSelectedPrompt] = useState<Prompt | null>(null);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isPromptModalOpen, setIsPromptModalOpen] = useState(false);
  const [editingPrompt, setEditingPrompt] = useState<Prompt | null>(null);
  const [tooltip, setTooltip] = useState<{ message: string; position: { x: number; y: number } } | null>(null);

  useEffect(() => {
    const storedApiKey = localStorage.getItem('apiKey');
    if (storedApiKey) {
      setApiKey(storedApiKey);
    }
    const storedPrompts = JSON.parse(localStorage.getItem('userPrompts') || '[]');
    const allPrompts = [...systemPrompts, ...storedPrompts];
    setPrompts(allPrompts);
    setSelectedPrompt(systemPrompts[0]); // 默认选中第一个系统提示词
  }, []);

  const updateApiKey = (newApiKey: string) => {
    setApiKey(newApiKey);
    localStorage.setItem('apiKey', newApiKey);
  };

  const addImages = (newImages: Image[]) => {
    setImages(prevImages => {
      const updatedImages = [...prevImages, ...newImages];
      console.log('Updated images in App:', updatedImages); // 添加日志
      return updatedImages;
    });
  };

  const clearImages = () => {
    setImages([]);
  };

  const addPrompt = (newPrompt: Prompt) => {
    setPrompts(prevPrompts => {
      const updatedPrompts = [...prevPrompts, newPrompt];
      localStorage.setItem('userPrompts', JSON.stringify(updatedPrompts.filter(p => !p.isSystem)));
      return updatedPrompts;
    });
  };

  const updatePrompt = (updatedPrompt: Prompt) => {
    setPrompts(prevPrompts => {
      const updatedPrompts = prevPrompts.map(p => p.id === updatedPrompt.id ? updatedPrompt : p);
      localStorage.setItem('userPrompts', JSON.stringify(updatedPrompts.filter(p => !p.isSystem)));
      return updatedPrompts;
    });
  };

  const selectPrompt = (prompt: Prompt | null) => {
    setSelectedPrompt(prompt);
  };

  const clearChat = () => {
    setChatHistory([]);
    clearImages();
  };

  const deletePrompt = (promptId: string) => {
    setPrompts(prevPrompts => {
      const updatedPrompts = prevPrompts.filter(p => p.id !== promptId);
      localStorage.setItem('userPrompts', JSON.stringify(updatedPrompts.filter(p => !p.isSystem)));
      return updatedPrompts;
    });
    if (selectedPrompt && selectedPrompt.id === promptId) {
      setSelectedPrompt(null);
    }
  };

  const handleSavePrompt = (prompt: Prompt) => {
    const updatedPrompts = prompts.map(p => p.id === prompt.id ? prompt : p);
    if (!updatedPrompts.find(p => p.id === prompt.id)) {
      updatedPrompts.push(prompt);
    }
    setPrompts(updatedPrompts);
    localStorage.setItem('userPrompts', JSON.stringify(updatedPrompts.filter(p => !p.isSystem)));
    setEditingPrompt(null);
    setIsPromptModalOpen(false);
    setSelectedPrompt(prompt); // 立即选中新保存的提示词
  };

  const handleDeletePrompt = (promptId: string) => {
    deletePrompt(promptId);
    setEditingPrompt(null);
  };

  const showTooltip = (message: string, position: { x: number; y: number }) => {
    setTooltip({ message, position });
    setTimeout(() => setTooltip(null), 2000);
  };

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <header className="bg-white shadow-md p-4">
        <h1 className="text-2xl font-bold text-center text-gray-800">AI SVG 图像生成</h1>
      </header>
      <div className="flex flex-1 overflow-hidden">
        <ChatWindow
          chatHistory={chatHistory}
          setChatHistory={setChatHistory}
          images={images}
          addImages={addImages}
          clearImages={clearImages}
          apiKey={apiKey}
          setApiKey={updateApiKey}
          prompts={prompts}
          addPrompt={handleSavePrompt} // 使用handleSavePrompt替代addPrompt
          updatePrompt={handleSavePrompt} // 使用handleSavePrompt替代updatePrompt
          selectedPrompt={selectedPrompt}
          selectPrompt={selectPrompt}
          deletePrompt={handleDeletePrompt}
          clearChat={clearChat} // 新增
          showTooltip={showTooltip}
        />
        <div className="w-16 flex flex-col justify-center items-center bg-gray-200">
          <button 
            onClick={() => setIsSettingsOpen(true)} 
            className="mb-4 p-2 bg-gray-500 text-white rounded-full hover:bg-gray-600 transition-colors"
            title="打开设置"
          >
            <CogIcon className="h-5 w-5" />
          </button>
          <button 
            onClick={clearChat} 
            className="mb-4 p-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors"
            title="清除聊天记录和图片"
          >
            <TrashIcon className="h-5 w-5" />
          </button>
          <a 
            href="https://faka.zmgpt.cc" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="mb-4 p-2 bg-green-500 text-white rounded-full hover:bg-green-600 transition-colors"
            title="购买API密钥"
          >
            <ShoppingCartIcon className="h-5 w-5" />
          </a>
        </div>
        <ImageGallery images={images} />
      </div>
      <SettingsModal isOpen={isSettingsOpen} onClose={() => setIsSettingsOpen(false)} setApiKey={updateApiKey} />
      <PromptModal
        isOpen={isPromptModalOpen}
        onClose={() => setIsPromptModalOpen(false)}
        onSave={handleSavePrompt}
        onDelete={handleDeletePrompt}
        prompt={editingPrompt}
      />
      {tooltip && <TooltipPortal message={tooltip.message} position={tooltip.position} />}
    </div>
  );
}

export default App;