import React, { useState, useEffect } from 'react';
import { toPng } from 'html-to-image';

interface ImageGalleryProps {
  images: { svg: string; png: string; }[];
}

function ImageGallery({ images }: ImageGalleryProps) {
  const [selectedImages, setSelectedImages] = useState<number[]>([]);
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [fullSizeImage, setFullSizeImage] = useState<string | null>(null);

  useEffect(() => {
    const calculateImageSize = () => {
      const galleryWidth = document.querySelector('.image-gallery')?.clientWidth || 0;
      const imageWidth = Math.floor((galleryWidth - 40) / 3) - 16;
      const imageHeight = Math.floor(imageWidth * 5 / 4);
      setImageSize({ width: imageWidth, height: imageHeight });
    };

    calculateImageSize();
    window.addEventListener('resize', calculateImageSize);
    return () => window.removeEventListener('resize', calculateImageSize);
  }, []);

  const downloadImage = async (svg: string, index: number, format: 'svg' | 'png') => {
    if (format === 'svg') {
      // 直接下载原始SVG
      const blob = new Blob([svg], { type: 'image/svg+xml' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `image-${index + 1}.svg`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } else {
      // 创建一个临时的div元素来包含SVG
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = svg;
      const svgElement = tempDiv.querySelector('svg');
      document.body.appendChild(tempDiv);

      if (svgElement) {
        const width = svgElement.getAttribute('width');
        const height = svgElement.getAttribute('height');

        if (width && height) {
          // 设置div的尺寸以匹配SVG
          tempDiv.style.width = width + 'px';
          tempDiv.style.height = height + 'px';
          
          const dataUrl = await toPng(tempDiv, { 
            quality: 1,
            width: parseInt(width),
            height: parseInt(height)
          });
          const link = document.createElement('a');
          link.href = dataUrl;
          link.download = `image-${index + 1}.png`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }

      document.body.removeChild(tempDiv);
    }
  };

  const downloadSelected = async () => {
    for (const index of selectedImages) {
      await downloadImage(images[index].svg, index, 'png');
    }
    setSelectedImages([]);
  };

  const toggleImageSelection = (index: number) => {
    setSelectedImages(prev => 
      prev.includes(index) ? prev.filter(i => i !== index) : [...prev, index]
    );
  };

  const showFullSizeImage = (svg: string) => {
    setFullSizeImage(svg);
  };

  return (
    <div className="w-1/2 bg-gray-100 p-4 overflow-y-auto image-gallery flex flex-col">
      <h2 className="text-2xl font-bold mb-4 text-gray-800">生成的图片 ({images.length})</h2>
      <div className="flex-grow grid grid-cols-3 gap-4 overflow-y-auto">
        {images.map((image, index) => (
          <div key={index} className="bg-white p-2 rounded shadow-md relative flex justify-center items-center">
            <div 
              id={`svg-${index}`}
              className="overflow-hidden cursor-pointer"
              style={{
                width: `${imageSize.width}px`,
                height: `${imageSize.height}px`,
              }}
              onClick={() => showFullSizeImage(image.svg)}
            >
              <img 
                src={`data:image/svg+xml;utf8,${encodeURIComponent(image.svg)}`} 
                alt={`Generated SVG ${index + 1}`}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain'
                }}
              />
            </div>
            <div className="mt-2 flex justify-between items-center w-full absolute bottom-2 left-2 right-2">
              <button 
                onClick={() => downloadImage(image.svg, index, 'svg')} 
                className="px-2 py-1 bg-blue-500 text-white rounded text-xs hover:bg-blue-600 transition-colors"
              >
                下载 SVG
              </button>
              <button 
                onClick={() => downloadImage(image.svg, index, 'png')} 
                className="px-2 py-1 bg-green-500 text-white rounded text-xs hover:bg-green-600 transition-colors"
              >
                下载 PNG
              </button>
            </div>
            <input 
              type="checkbox" 
              checked={selectedImages.includes(index)}
              onChange={() => toggleImageSelection(index)}
              className="absolute top-1 right-1 h-4 w-4"
            />
          </div>
        ))}
      </div>
      <div className="mt-4 border-t pt-4 sticky bottom-0 bg-gray-100">
        <button 
          onClick={downloadSelected} 
          className={`w-full px-4 py-2 rounded transition-colors ${
            selectedImages.length > 0
              ? 'bg-blue-500 text-white hover:bg-blue-600'
              : 'bg-gray-300 text-gray-500 cursor-not-allowed'
          }`}
          disabled={selectedImages.length === 0}
        >
          下载选中的图片 (PNG)
        </button>
      </div>
      {fullSizeImage && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50" onClick={() => setFullSizeImage(null)}>
          <div className="bg-white p-4 rounded-lg">
            <img 
              src={`data:image/svg+xml;utf8,${encodeURIComponent(fullSizeImage)}`} 
              alt="Full size SVG"
              className="max-w-full max-h-full"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ImageGallery;