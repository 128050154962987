import React, { useState } from 'react';
import { PaperAirplaneIcon } from '@heroicons/react/24/solid';
import { Prompt } from '../types';

interface InputAreaProps {
  onSendMessage: (message: string) => void;
  selectedPrompt: Prompt | null;
}

function InputArea({ onSendMessage, selectedPrompt }: InputAreaProps) {
  const [message, setMessage] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (message.trim()) {
      onSendMessage(message);
      setMessage('');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col">
      {selectedPrompt && (
        <div className="mb-2 p-2 bg-gray-100 rounded text-sm">
          使用提示词: {selectedPrompt.name}
        </div>
      )}
      <div className="flex">
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="flex-1 p-2 border rounded-l"
          placeholder="输入消息..."
        />
        <button
          type="submit"
          className="px-4 py-2 bg-blue-500 text-white rounded-r hover:bg-blue-600 transition-colors"
        >
          发送
        </button>
      </div>
    </form>
  );
}

export default InputArea;