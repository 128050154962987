import React from 'react';
import { Prompt } from '../types';

interface PromptIconProps {
  prompt: Prompt;
  onEdit: (prompt: Prompt) => void;
  isSelected: boolean;
  onSelect: () => void;
  showTooltip: (message: string, position: { x: number; y: number }) => void;
}

function PromptIcon({ prompt, onEdit, isSelected, onSelect, showTooltip }: PromptIconProps) {
  const handleDoubleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (prompt.isSystem) {
      const rect = event.currentTarget.getBoundingClientRect();
      showTooltip('系统提示词，无法编辑', { x: rect.left, y: rect.bottom + 5 });
    } else {
      onEdit(prompt);
    }
  };

  return (
    <button
      onClick={onSelect}
      onDoubleClick={handleDoubleClick}
      className={`p-2 rounded-full text-white ${
        isSelected ? 'ring-2 ring-offset-2 ring-blue-500' : ''
      } ${prompt.isSystem ? 'bg-gray-500 hover:bg-gray-600' : 'bg-blue-500 hover:bg-blue-600'} transition-colors`}
      title={prompt.name}
    >
      {prompt.icon}
    </button>
  );
}

export default React.memo(PromptIcon);