import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Prompt } from '../types';
import Draggable from 'react-draggable';

const iconList: string[] = [
  "📊", "📝", "📌", "🔍", "💡", "🎨", "📁", "📆", "⏰", "⏳",
  "🚀", "🌟", "🔮", "🧩", "🔑", "📢", "🔔", "👁️", "🧠", "💻", 
  "📱", "⌨️", "🖱️", "💾", "🧮", "📷", "🔬", "🔭", "⚗️", "🧪",
  "🧫", "🧬", "⚒️", "⚙️", "⚖️", "🔗", "🧰", "💳", "💎", "🏆", 
  "⚽", "🎾", "🎱", "🏋️", "🏇", "🧘", "🏊", "🎭", "🎮", "🌏", 
  "🏞️", "🏛️", "🏗️", "🏠", "🏢", "🕌", "🚀", "🚗"
];

interface PromptModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (prompt: Prompt) => void;
  onDelete: (promptId: string) => void;
  prompt: Prompt | null;
}

function PromptModal({ isOpen, onClose, onSave, onDelete, prompt }: PromptModalProps) {
  const [name, setName] = useState('');
  const [content, setContent] = useState('');
  const [icon, setIcon] = useState(iconList[0] || '');
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (prompt) {
      setName(prompt.name);
      setContent(prompt.content);
      setIcon(prompt.icon);
    } else {
      setName('');
      setContent('');
      setIcon(iconList[0] || '');
    }
  }, [prompt]);

  useEffect(() => {
    if (isOpen && modalRef.current) {
      const viewportHeight = window.innerHeight;
      const viewportWidth = window.innerWidth;
      const modalHeight = modalRef.current.offsetHeight;
      const modalWidth = modalRef.current.offsetWidth;

      const topPosition = Math.max(0, (viewportHeight - modalHeight) / 2 - 50); // 稍微向上偏移
      const leftPosition = Math.max(0, (viewportWidth - modalWidth) / 3); // 偏左位置

      modalRef.current.style.top = `${topPosition}px`;
      modalRef.current.style.left = `${leftPosition}px`;
    }
  }, [isOpen]);

  const handleSave = () => {
    if (prompt && prompt.isSystem) {
      alert('无法修改系统提示词');
      return;
    }
    const newPrompt: Prompt = {
      id: prompt ? prompt.id : (uuidv4 as () => string)(),
      name,
      content,
      icon,
      isSystem: false,
    };
    onSave(newPrompt);
    onClose();
  };

  const handleDelete = () => {
    if (prompt && !prompt.isSystem) {
      onDelete(prompt.id);
      onClose();
    }
  };

  if (!isOpen) return null;

  const isSystemPrompt = prompt?.isSystem ?? false;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <Draggable handle=".modal-header" bounds="parent">
        <div 
          ref={modalRef} 
          className="absolute bg-white shadow-lg rounded-lg overflow-hidden"
          style={{ width: '400px' }} // 固定宽度
        >
          <div className="modal-header cursor-move p-4 bg-gray-100 flex justify-between items-center">
            <h3 className="text-lg font-bold">{prompt ? (isSystemPrompt ? '查看系统提示词' : '编辑提示词') : '添加提示词'}</h3>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              &times;
            </button>
          </div>
          <div className="p-4">
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="提示词名称"
              className="mt-2 w-full p-2 border rounded"
              disabled={isSystemPrompt}
            />
            <textarea
              value={content}
              onChange={(e) => setContent(e.target.value)}
              placeholder="提示词内容"
              className="mt-2 w-full p-2 border rounded h-40"
              disabled={isSystemPrompt}
            />
            <div className="mt-2">
              <label className="block mb-1">选择图标：</label>
              <div className="grid grid-cols-10 gap-1">
                {iconList.map((iconOption) => (
                  <button
                    key={iconOption}
                    onClick={() => setIcon(iconOption)}
                    className={`p-1 text-xl ${icon === iconOption ? 'bg-blue-100' : 'bg-gray-100'} rounded`}
                    disabled={isSystemPrompt}
                  >
                    {iconOption}
                  </button>
                ))}
              </div>
            </div>
            <div className="mt-4 flex justify-between">
              {prompt && !isSystemPrompt && (
                <button onClick={handleDelete} className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600">删除</button>
              )}
              <div>
                <button onClick={onClose} className="mr-2 px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400">
                  {isSystemPrompt ? '关闭' : '取消'}
                </button>
                {!isSystemPrompt && (
                  <button onClick={handleSave} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">保存</button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Draggable>
    </div>
  );
}

export default PromptModal;